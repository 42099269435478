<template>
  <div>
  <!-- <div id="imagenheader"></div> -->
  <div id="nav">

    <!-- <router-link to="/">Home</router-link> 
    <router-link to="/servicios">Servicios</router-link> 
    <router-link to="/proyectos">Proyectos</router-link> 
    <router-link to="/about">About</router-link> 
    <router-link to="/contacts">Contacts</router-link>  -->

  <!-- <a  href="#firstPage" class="active">Home</a>
  <a href="#about">About</a>
  <a  href="#secondPage">Services</a>
  <a href="#contact">Contact</a> -->

 <a  class="active" @click="Goto('home')">Inicio</a>
 <a  @click="Goto('servicios-generales')">Servicios</a>
 <!-- <a  @click="Goto('servicios-hogar')">Hogar</a>
  <a  @click="Goto('servicios-industria')">Industria</a> -->
   <a  @click="Goto('clientes')">Clientes</a>
  <a  @click="Goto('contacto')">Contacto</a>


  </div>
  </div>
</template>

<script>
export default {
name: "AppLayoutLinks",
methods:{
    Goto(seccion){
      //console.log(seccion)
    if(document.getElementById(seccion)){
  document.getElementById(seccion).scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }
}
}
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
#imagenheader{
  position: relative;
  left:0;
  top:10vh;
  height: 10vh;
  background-image: url('../assets/img/logo/imagenes/aa.png');
  background-size: contain;
  z-index: 10;
}
#nav {
  position: fixed;
  top:0;
  left:0;
  padding-top: 2vh;
  margin-bottom: 1vh;
  padding-right: 1vw;
  width: 100vw !important;
  background-color: rgba(0,0,0,0.5);
  /* background-color: rgba(20, 20, 20,0.4); */
  /* background-color: rgba(125, 217, 89,1); */
  opacity: 0.8;
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 20;
  
}
#nav a {
  font-weight: bold;
  /* color: #091d01; */
  color:rgb(255, 255, 255);
  padding-right: 2vw;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  cursor: pointer;
}
#nav a.router-link-exact-active {
  color: #929292;
}
</style>